import classnames from 'classnames';
import { formatMoney } from 'shared/utils';
import Cell from './Cell';

export default function DollarCell({ className, value, ...props }: any) {
  const mapped = formatMoney(value);
  return (
    <Cell
      className={classnames(className, 'dollar-cell')}
      value={mapped}
      {...props}
    />
  );
}
