import { createSlice } from '@reduxjs/toolkit';
import { FeatureFlagsDto } from 'api';
import { fetchFeatureFlags } from './actions';

const initialFlagsState: FeatureFlagsDto = {
  beadPay: true,
};

const slice = createSlice({
  name: 'features',
  initialState: {
    flags: initialFlagsState,
  },
  reducers: {},
  extraReducers: {
    [fetchFeatureFlags.pending.type]: state => {
      state.flags = initialFlagsState;
    },
    [fetchFeatureFlags.fulfilled.type]: (state, { payload }) => {
      state.flags = payload;
    },
  },
});

export const { actions, reducer } = slice;
export type FeatureSlice = ReturnType<typeof reducer>;
