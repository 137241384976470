import React, { useReducer, useEffect, useRef, useState } from 'react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from 'shared/state';

const addVisibleLoading = 'addVisibleLoading';
const addBackgroundLoading = 'addBackgroundLoading';
const removePendingRequest = 'removePendingRequest';
const resetRequests = 'resetRequests';

type HandleableAxiosError = AxiosError & { handled: boolean };
interface AxiosRequestConfigWithLoading extends AxiosRequestConfig {
  hasLoadingMessage?: boolean;
}
type LoadingState = {
  count: number;
  loadingVisible: boolean;
};

type Action = { type: string };
const initialState: LoadingState = { count: 0, loadingVisible: false };

function loadingReducer(state: LoadingState, action: Action) {
  switch (action.type) {
    case resetRequests:
      return initialState;
    case addVisibleLoading:
      return { count: state.count + 1, loadingVisible: true };
    case addBackgroundLoading:
      return { count: state.count + 1, loadingVisible: state.loadingVisible };
    case removePendingRequest:
      const newCount = Math.max(state.count - 1, 0);
      return {
        count: newCount,
        loadingVisible: state.loadingVisible && newCount > 0,
      };
    default:
      throw new Error(action.type);
  }
}

type Renderable = {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  render?: (props: LoadingParam) => any;
};

type LoadingParam = {
  loading: boolean;
};

export default function AxiosInterceptor({ component, render }: Renderable) {
  const dispatch = useDispatch();
  const [loadingState, loadingDispatch] = useReducer(
    loadingReducer,
    initialState
  );
  const [requestInterceptors, setRequestInterceptors] = useState<number[]>([]);
  const [responseInterceptors, setResponseInterceptors] = useState<number[]>(
    []
  );

  const errorRef: React.MutableRefObject<
    ((err: HandleableAxiosError) => void) | undefined
  > = useRef();
  // we don't want some closures (e.g. history) that 'on error' needs to cause the interceptors to be invalidated
  // e.g. start load -> navigate -> finish load should not cause a toast to be stuck as visible forever
  errorRef.current = onAxiosError;

  const history = useHistory();

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      config => {
        // Do something before request is sent
        onStartRequest(config);
        return config;
      },
      error => {
        onRequestFinish();
        return Promise.reject(error);
      }
    );

    setRequestInterceptors(prevState => [...prevState, requestInterceptor]);

    const responseInterceptor = axios.interceptors.response.use(
      response => {
        onRequestFinish();
        return response;
      },
      err => {
        onRequestFinish();
        errorRef.current && errorRef.current(err);
        return Promise.reject(err);
      }
    );

    setResponseInterceptors(prevState => {
      return [...prevState, responseInterceptor];
    });

    function onStartRequest(config: AxiosRequestConfigWithLoading) {
      if (!config.hasLoadingMessage) {
        loadingDispatch({ type: addVisibleLoading });
      } else {
        loadingDispatch({ type: addBackgroundLoading });
      }
    }

    function onRequestFinish() {
      loadingDispatch({ type: removePendingRequest });
    }
  }, []);

  useEffect(() => {
    return () => {
      if (
        requestInterceptors.length !== 0 &&
        responseInterceptors.length !== 0
      ) {
        requestInterceptors.forEach(x => axios.interceptors.request.eject(x));
        responseInterceptors.forEach(x => axios.interceptors.response.eject(x));
        setRequestInterceptors([]);
        setResponseInterceptors([]);
        loadingDispatch({ type: resetRequests });
      }
    };
  }, [requestInterceptors, responseInterceptors]);

  return component
    ? React.createElement(component, { loading: loadingState.loadingVisible })
    : render
    ? render({ loading: loadingState.loadingVisible })
    : 'No component or render prop provided';

  function onAxiosError(err: HandleableAxiosError) {
    const status = err?.response?.status;
    if (axios.isCancel(err)) {
      err.handled = true;
    } else if (status === 401) {
      toast.error(`You need to login to view this page`);
      dispatch(authActions.setNoAuth());
      err.handled = true;
    } else if (status === 403) {
      toast.error(`You are not authorized to do this.`);
      history.replace('/403');
      err.handled = true;
    } else if (status === 500) {
      toast.error(
        `Something went wrong talking to the portal. Contact support if this continues.`
      );
      err.handled = true;
    }
  }
}
