import { useEffect } from 'react';
import { featureActions, useReduxDispatch } from 'shared/state';

export function FeatureManager() {
  const dispatch = useReduxDispatch();
  useEffect(() => {
    dispatch(featureActions.fetchFeatureFlags());
  }, [dispatch]);
  return null;
}
