import { ChildrenProps } from 'typings';
import { Header } from 'shared/components';

export default function BaseUIWrapper({ children }: ChildrenProps) {
  return (
    <div className="c-app">
      <div className="c-wrapper c-wrapper-fluid">
        <div className="c-header c-header-fixed">
          <Header.Brand />
          <Header.Navigation />
          <Header.Banner />
          <Header.SubBanner />
        </div>
        {children}
      </div>
    </div>
  );
}
