import { createSelector } from '@reduxjs/toolkit';
import { Claim } from 'features/Auth';
import { AuthReduxState } from './types';

export const getAuth = (state: AuthReduxState) => state.auth;
export const getUser = (state: AuthReduxState) => getAuth(state).user;
export const getCookieExpiration = (state: AuthReduxState) =>
  getAuth(state).cookieExpirationMinutes;
export const getUserClaims = (state: AuthReduxState): Claim[] =>
  (getUser(state)?.claimList as Claim[]) ?? [];
export const getAuthTicketState = createSelector(
  getAuth,
  state => state.authTicketState
);
