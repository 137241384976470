import './Login.scss';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Form, IconInput, SubmitFormButton, validators } from 'envoc-form';
import { Button } from 'reactstrap';
import { Body } from 'shared/components';
import { useLoginRedirect } from 'shared/hooks';
import { authSelectors, authActions, authTicketStates } from 'shared/state';
import { AccountService, PasswordLoginCommand } from 'api';

export default function Login() {
  const dispatch = useDispatch();
  const { restoreFromLogin } = useLoginRedirect();

  const { authTicketState, user } = useSelector(authSelectors.getAuth);

  useEffect(() => {
    dispatch(authActions.allowRedirects());
  }, [dispatch]);

  useEffect(() => {
    if (
      (user && authTicketState === authTicketStates.userAuthTicketChecked) ||
      authTicketState === authTicketStates.userAuthTicketChecking
    ) {
      restoreFromLogin();
    }
  });

  return (
    <Body className="unauthenticated">
      <div className="page sign-in-page">
        <Form onSubmit={handleSubmit} ignoreLostChanges={true}>
          <h1>Sign-in</h1>
          <p className="text-muted">to your administrator account</p>
          <IconInput
            name="email"
            placeholder="Email"
            autoComplete="username"
            icon="icon-user"
            validate={validators.required}
          />
          <IconInput
            icon="icon-lock"
            type="password"
            placeholder="Password"
            name="password"
            autoComplete="current-password"
            validate={validators.required}
          />
          <div>
            <SubmitFormButton
              allowPristineSubmit={true}
              size="lg"
              className="sign-in">
              Sign in
            </SubmitFormButton>{' '}
            <Button tag={Link} to={'/'} size="lg">
              Return Home
            </Button>
          </div>
          <div className="forgot-password">
            <Link to="/forgot-password">Forgot password?</Link>
          </div>
        </Form>
      </div>
    </Body>
  );

  function handleSubmit(values: PasswordLoginCommand) {
    return AccountService.login({ body: values }).then(resp => {
      const signInResult = resp.result;
      if (signInResult) {
        dispatch(authActions.loadUser());
      }
    });
  }
}
