import { createAuth, CreateRequirement } from 'auth';
import { authSelectors, useReduxSelector } from 'shared/state';

export const auth = createAuth({
  usePermissionSelector: () => useReduxSelector(authSelectors.getUserClaims),
});

export const { Authorized, useAuthorize, usePermissions } = auth;

export type Requirement = CreateRequirement<typeof auth>;
