import './Navigation.scss';
import { Nav, NavItem } from 'reactstrap';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NavigationContextLink } from 'typings';
import { useNavigation } from 'shared/contexts';
import { authSelectors } from 'shared/state';
import HeaderDropdown from './HeaderDropdown';

export default function Navigation() {
  const { links } = useNavigation();
  const user = useSelector(authSelectors.getUser);
  return (
    <header className="c-header-nav c-header-fixed c-header-navigation navbar">
      {links?.length ? (
        <Nav navbar>
          {links.map(({ key, ...link }) => (
            <NavigationLink key={key} {...link} />
          ))}
        </Nav>
      ) : null}
      {user && (
        <Nav className="ml-auto user-dropdown" navbar>
          <HeaderDropdown />
        </Nav>
      )}
    </header>
  );
}

function NavigationLink(props: NavigationContextLink<any>) {
  const { to } = props;
  const match = useRouteMatch(to);
  const className = match ? 'active' : '';
  return (
    <NavItem className={className}>
      <NavLink className="btn" {...props} />
    </NavItem>
  );
}
