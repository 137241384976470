import { Dispatch, SetStateAction } from 'react';
import { BannerContextValue } from 'typings';
import { createStrictContext } from '../utils';

const [SetSubBannerProvider, useSetSubBanner] = createStrictContext<
  Dispatch<SetStateAction<BannerContextValue>>
>({
  name: 'SetSubBanner',
});

export { SetSubBannerProvider, useSetSubBanner };
