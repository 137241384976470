import { Authorized } from 'features/Auth';
import { Link } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Button } from 'reactstrap';

interface CitationLinkable {
  citationId: number;
  citationNumber: string;
}

export default function CitationLinkCell<
  TCitationLinkable extends CitationLinkable
>({ row }: CellProps<TCitationLinkable>) {
  const { original } = row;
  return (
    <Authorized
      requires="Citations - View Details"
      fallback={original.citationNumber}>
      <Button
        tag={Link}
        to={`/admin/citations/${original.citationId}/details`}
        title="Navigate to Citation"
        color="link"
        target="_blank"
        rel="noopener noreferrer">
        {original.citationNumber}
      </Button>
    </Authorized>
  );
}
