import { useLocation, useHistory } from 'react-router';
import qs from 'qs';

const urlParameterName = 'returnUrl';

// TODO: I don't really like this name, thoughts?
export default function useLoginRedirect() {
  const location = useLocation();
  const history = useHistory();
  const returnUrl = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })[urlParameterName];

  return {
    redirectToLogin: redirectToLogon,
    restoreFromLogin: restoreFromLogin,
  };

  function redirectToLogon(noRedirect: boolean) {
    if (noRedirect) {
      history.push('/login');
    } else {
      history.push(
        '/login?' +
          qs.stringify({
            [urlParameterName]: `${location.pathname}${location.search}`,
          })
      );
    }
  }

  function restoreFromLogin() {
    history.replace((returnUrl as string) || '/');
  }
}
