/** Generate by swagger-axios-codegen */
// @ts-ignore
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-ignore
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AccountService {
  /**
   *
   */
  static getLoggedInUserDetails(options: IRequestOptions = {}): Promise<LoggedInUserDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/account/me';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalLogin(
    params: {
      /**  */
      provider?: string;
      /**  */
      returnUrl?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/account/ExternalLogin';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { provider: params['provider'], returnUrl: params['returnUrl'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalLoginCallback(
    params: {
      /**  */
      returnUrl?: string;
      /**  */
      remoteError?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/account/ExternalLoginCallback';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { returnUrl: params['returnUrl'], remoteError: params['remoteError'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static register(
    params: {
      /**  */
      returnUrl?: string;
      /** requestBody */
      body?: RegisterUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/account/register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { returnUrl: params['returnUrl'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static login(
    params: {
      /** requestBody */
      body?: PasswordLoginCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/account/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static forgotPassword(
    params: {
      /** requestBody */
      body?: ForgotPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/account/forgotpassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPassword(
    params: {
      /** requestBody */
      body?: ResetPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/account/resetpassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /** requestBody */
      body?: ChangePasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/account/changepassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeEmail(
    params: {
      /** requestBody */
      body?: ChangeEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/account/changeemail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static logout(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/account/logout';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confirmAccountRedirect(
    params: {
      /**  */
      email?: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/account/confirm-account-redirect';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { email: params['email'], token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confirmAccount(
    params: {
      /**  */
      email?: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountConfirmationResultApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/account/confirm-account';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { email: params['email'], token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AdminCitationService {
  /**
   *
   */
  static searchCitations(
    params: {
      /**  */
      search?: string;
      /**  */
      caseNumber?: string;
      /**  */
      citationNumber?: string;
      /**  */
      ldwfNumber?: string;
      /**  */
      firstName?: string;
      /**  */
      middleName?: string;
      /**  */
      lastName?: string;
      /**  */
      citationDate?: string;
      /**  */
      regionId?: number;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SearchCitationsListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/citations/search';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        Search: params['search'],
        CaseNumber: params['caseNumber'],
        CitationNumber: params['citationNumber'],
        LdwfNumber: params['ldwfNumber'],
        FirstName: params['firstName'],
        MiddleName: params['middleName'],
        LastName: params['lastName'],
        CitationDate: params['citationDate'],
        RegionId: params['regionId']
      };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCitationById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdminCitationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/citations/by-id/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateCitationById(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditCitationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/citations/by-id/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCitationSummaryById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdminCitationSummaryDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/citations/summary/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCourseHistoryById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdminCitationCourseHistoryDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/citations/course-history/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransactions(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CitationTransactionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/citations/{id}/transactions';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AdminCitationAttachmentService {
  /**
   *
   */
  static getAttachments(
    params: {
      /**  */
      citationId: number;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CitationAttachmentListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/citation-attachments/by-citation-id/{citationId}';
      url = url.replace('{citationId}', params['citationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /** requestBody */
      body?: CitationAttachmentEditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/citation-attachments';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /** requestBody */
      body?: DeleteCitationAttachmentCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/citation-attachments';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AdminDepositService {
  /**
   *
   */
  static list(
    params: {
      /** requestBody */
      body?: GetDepositsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DepositListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/deposits/list';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportList(
    params: {
      /** requestBody */
      body?: GetDepositsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/deposits/list/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DepositDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/deposits/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditDepositRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/deposits/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listTransactions(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DepositTransactionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/deposits/{id}/transactions/list';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportTransactions(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/deposits/{id}/transactions/list/export';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeTransaction(
    params: {
      /**  */
      id: number;
      /**  */
      transactionId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/deposits/{id}/transactions/{transactionId}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{transactionId}', params['transactionId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static open(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/deposits/{id}/open';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static close(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/deposits/{id}/close';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDraft(
    params: {
      /** requestBody */
      body?: GetDepositDraftQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DepositDraftDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/deposits/draft';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listDraftTransactions(
    params: {
      /** requestBody */
      body?: GetDepositDraftTransactionsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DepositDraftTransactionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/deposits/draft/transactions/list';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateDepositCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/deposits';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addTransactionRange(
    params: {
      /** requestBody */
      body?: AddDepositTransactionsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/deposits/draft/transactions/range';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AdminNoteService {
  /**
   *
   */
  static getAllNotesByCitationId(
    params: {
      /**  */
      citationId: number;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NoteListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/notes/by-citation-id/{citationId}';
      url = url.replace('{citationId}', params['citationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createStaffNote(
    params: {
      /**  */
      citationId: number;
      /** requestBody */
      body?: CreateStaffNoteCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/notes/by-citation-id/{citationId}/create';
      url = url.replace('{citationId}', params['citationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AdminPaymentService {
  /**
   *
   */
  static createManualPayment(
    params: {
      /**  */
      citationId: number;
      /** requestBody */
      body?: CreatePaymentCommandRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/payments/by-citation-id/{citationId}/create';
      url = url.replace('{citationId}', params['citationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/payments/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AdminReportsService {
  /**
   *
   */
  static getLegacyDocketReport(
    params: {
      /** requestBody */
      body?: GetLegacyDocketReportQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/export/legacy-docket-report';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDocketReport(
    params: {
      /** requestBody */
      body?: GetDocketReportQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/export/docket-report';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAmountsPaidReport(
    params: {
      /** requestBody */
      body?: GetAmountsPaidReportFileByTransactionDateRangeQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/amounts-paid-report';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDailyBalancingReport(
    params: {
      /** requestBody */
      body?: GetDailyBalancingReportFileByDepositQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/daily-balancing-report';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMonthlyBalancingReport(
    params: {
      /** requestBody */
      body?: GetMonthlyBalancingReportFileByMonthQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/monthly-balancing-report';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getBeadPayReport(
    params: {
      /** requestBody */
      body?: GetBeadPayReportQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/bead-pay-report';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getReport(
    params: {
      /** requestBody */
      body?: GetReportFileQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/reports/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BeadPayService {
  /**
   *
   */
  static getSettings(options: IRequestOptions = {}): Promise<BeadPaySettingsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/bead-pay/settings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createPayment(
    params: {
      /** requestBody */
      body?: PayCivilFineUsingBeadPayCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PayCivilFineUsingBeadPayResponseApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/bead-pay/payments';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static handle(
    params: {
      /** requestBody */
      body?: PaymentWebhookPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/bead-pay/webhook-requests';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DefendantCitationService {
  /**
   *
   */
  static getAllCitationsForDefendantByCitationId(
    params: {
      /**  */
      citationNumber: string;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CitationListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/citations/by-number/{citationNumber}';
      url = url.replace('{citationNumber}', params['citationNumber'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCitationDetailsById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CitationDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/citations/by-id/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static checkIfCitationExists(
    params: {
      /**  */
      citationNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/citations/exists/{citationNumber}';
      url = url.replace('{citationNumber}', params['citationNumber'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPaymentHistoryByCitationId(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CitationDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/citations/payment-history/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCourseLoginUrlByCitationId(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CourseLoginUrlDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/citations/course-login-url/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static list(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViolatorTransactionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/citations/{id}/transactions/list';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DevelopmentService {
  /**
   *
   */
  static createAndRunImportJob(
    params: {
      /**  */
      startDate?: string;
      /**  */
      endDate?: string;
      /**  */
      isTest?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/development/import-jobs/create-and-run';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { startDate: params['startDate'], endDate: params['endDate'], isTest: params['isTest'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static seedProductInstancePrices(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/development/payment-resolution/seed-product-instance-prices';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static seedProductInstancePrice(
    params: {
      /**  */
      productInstanceId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/development/payment-resolution/seed-individual-product-instance-price';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { productInstanceId: params['productInstanceId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FeatureService {
  /**
   *
   */
  static getFeatureFlags(options: IRequestOptions = {}): Promise<FeatureFlagsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/features/flags';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FileResourcesService {
  /**
   *
   */
  static getBySasToken(
    params: {
      /**  */
      fileResourceGuid: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/file-resources/{fileResourceGuid}';
      url = url.replace('{fileResourceGuid}', params['fileResourceGuid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByGuid(
    params: {
      /**  */
      fileResourceGuid: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/file-resources/{fileResourceGuid}';
      url = url.replace('{fileResourceGuid}', params['fileResourceGuid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class OptionsService {
  /**
   *
   */
  static getDepositOptions(
    params: {
      /**  */
      depositStatusId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/options/deposits';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { depositStatusId: params['depositStatusId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDispositionOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/options/dispositions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getViolationLevelOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/options/violation-levels';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPaymentMethodOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/options/payment-methods';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDepositStatusOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/options/deposit-statuses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static depositYearOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/options/deposit-years';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static depositMonthOptions(
    params: {
      /**  */
      year?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/options/deposit-months';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransactionTypes(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/options/transaction-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PaymentService {
  /**
   *
   */
  static getCheckoutUrl(
    params: {
      /**  */
      citationId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payments/checkout-url';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { CitationId: params['citationId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RefundService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: RefundPaymentCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/refunds';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPaymentOptions(
    params: {
      /**  */
      citationId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/refunds/options/payments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { CitationId: params['citationId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransactionSourceOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/refunds/options/transaction-sources';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPaymentMethodOptions(
    params: {
      /**  */
      request?: GetPaymentMethodOptionsQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/refunds/options/payment-methods';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { request: params['request'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ReturnService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ReturnPaymentCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/returns';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPaymentOptions(
    params: {
      /**  */
      citationId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/returns/options/payments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { CitationId: params['citationId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransactionSourceOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/returns/options/transaction-sources';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPaymentMethodOptions(
    params: {
      /**  */
      request?: GetPaymentMethodOptionsQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/returns/options/payment-methods';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { request: params['request'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SettingsService {
  /**
   *
   */
  static getSettings(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/settings/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSettingById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteSettingById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSettingByKey(
    params: {
      /**  */
      key: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/settings/{key}';
      url = url.replace('{key}', params['key'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createSetting(
    params: {
      /** requestBody */
      body?: SettingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/settings';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateSetting(
    params: {
      /** requestBody */
      body?: SettingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/settings';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StatusService {
  /**
   *
   */
  static getStatusAsync(options: IRequestOptions = {}): Promise<StatusResultListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/status';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TransactionService {
  /**
   *
   */
  static list(
    params: {
      /** requestBody */
      body?: GetTransactionsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/transactions/list';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/transactions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportList(
    params: {
      /** requestBody */
      body?: GetTransactionsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/transactions/list/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setDeposit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: SetTransactionDepositRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/transactions/{id}/deposit';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UsersService {
  /**
   *
   */
  static getProfilePhoto(
    params: {
      /**  */
      userId: number;
      /**  */
      profilePhotoGuid: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/users/{userId}/profilePhoto/{profilePhotoGuid}';
      url = url.replace('{userId}', params['userId'] + '');
      url = url.replace('{profilePhotoGuid}', params['profilePhotoGuid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUsers(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/users/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUser(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postUserNoPhoto(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postUser(
    params: {
      /** requestBody */
      body?: CreateUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/users';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRoleOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/users/roleOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendConfirmEmail(
    params: {
      /** requestBody */
      body?: SendConfirmAccountEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/users/sendConfirmEmail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class VoidService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: VoidTransactionCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/voids';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransactionOptions(
    params: {
      /**  */
      citationId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/admin/voids/options/transactions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { CitationId: params['citationId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface AccountConfirmationResult {
  /**  */
  isPasswordResetRequired?: boolean;

  /**  */
  code?: string;
}

export interface AccountConfirmationResultApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: AccountConfirmationResult;
}

export interface AddDepositTransactionsCommand {
  /**  */
  depositId?: number;

  /**  */
  transactionIds?: number[];
}

export interface Address {
  /**  */
  street?: string;

  /**  */
  apartmentNumber?: string;

  /**  */
  city?: string;

  /**  */
  state?: string;

  /**  */
  postalCode?: string;
}

export interface AddressDto {
  /**  */
  street?: string;

  /**  */
  apartmentNumber?: string;

  /**  */
  city?: string;

  /**  */
  state?: string;

  /**  */
  postalCode?: string;

  /**  */
  string?: string;
}

export interface AdminCitationCourseHistoryDto {
  /**  */
  id?: number;

  /**  */
  courseName?: string;

  /**  */
  courseFee?: number;

  /**  */
  courseStatus?: string;

  /**  */
  courses?: AdminCourseHistoryListItemDto[];
}

export interface AdminCitationCourseHistoryDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: AdminCitationCourseHistoryDto;
}

export interface AdminCitationDto {
  /**  */
  id?: number;

  /**  */
  citationNumber?: string;

  /**  */
  reportNumber?: string;

  /**  */
  defendantName?: string;

  /**  */
  status?: string;

  /**  */
  citationDate?: Date;

  /**  */
  region?: number;

  /**  */
  district?: string;

  /**  */
  parish?: string;

  /**  */
  restitutionFee?: number;

  /**  */
  restitutionNumber?: string;

  /**  */
  hasRestitution?: boolean;

  /**  */
  courtDate?: Date;

  /**  */
  continuanceDate?: Date;

  /**  */
  primaryAgentName?: string;

  /**  */
  dateOfBirth?: Date;

  /**  */
  ldwfNumber?: string;

  /**  */
  driversLicenseNumber?: string;

  /**  */
  driversLicenseState?: string;

  /**  */
  emailAddress?: string;

  /**  */
  isFinePaid?: boolean;

  /**  */
  isCoursePassed?: boolean;

  /**  */
  citationAddress?: AddressDto;

  /**  */
  currentAddress?: AddressDto;

  /**  */
  addressesAreEqual?: boolean;

  /**  */
  violations?: object;

  /**  */
  notes?: StaffNotePreviewListItemDto[];

  /**  */
  course?: AdminCourseDto;

  /**  */
  isCourseEligible?: boolean;

  /**  */
  isPaymentEligible?: boolean;

  /**  */
  fineTotals?: AdminCitationFineTotalsDto;

  /**  */
  canEdit?: boolean;

  /**  */
  shouldShowCourseData?: boolean;
}

export interface AdminCitationDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: AdminCitationDto;
}

export interface AdminCitationFineTotalsDto {
  /**  */
  totalFine?: number;

  /**  */
  feeAmount?: number;

  /**  */
  courtCosts?: number;

  /**  */
  totalDue?: number;

  /**  */
  totalPaid?: number;

  /**  */
  balance?: number;
}

export interface AdminCitationSummaryDto {
  /**  */
  id?: number;

  /**  */
  violations?: AdminCitationSummaryViolationListItemDto[];

  /**  */
  totalFine?: number;

  /**  */
  feeAmount?: number;

  /**  */
  courtFee?: number;

  /**  */
  lessPaymentsReceived?: number;

  /**  */
  totalDue?: number;
}

export interface AdminCitationSummaryDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: AdminCitationSummaryDto;
}

export interface AdminCitationSummaryViolationListItemDto {
  /**  */
  id?: number;

  /**  */
  wildlifeAndFisheriesCode?: string;

  /**  */
  description?: string;

  /**  */
  repeatCount?: number;

  /**  */
  violationLevelName?: string;

  /**  */
  violationAmount?: number;

  /**  */
  courtFee?: number;

  /**  */
  fineAmount?: number;

  /**  */
  additionalAmount?: number;
}

export interface AdminCourseDto {
  /**  */
  id?: number;

  /**  */
  courseName?: string;

  /**  */
  courseFee?: number;

  /**  */
  courseStatus?: string;
}

export interface AdminCourseHistoryListItemDto {
  /**  */
  date?: Date;

  /**  */
  description?: string;
}

export interface AdminViolationListItemDto {
  /**  */
  id?: number;

  /**  */
  wildlifeAndFisheriesCode?: string;

  /**  */
  description?: string;

  /**  */
  repeatCount?: number;

  /**  */
  violationLevelName?: string;

  /**  */
  violationLevelId?: number;

  /**  */
  violationAmount?: number;

  /**  */
  dispositionId?: number;

  /**  */
  dispositionName?: string;

  /**  */
  dispositionDate?: Date;

  /**  */
  fineAmount?: number;

  /**  */
  courtFee?: number;

  /**  */
  additionalAmount?: number;
}

export interface Amounts {
  /**  */
  requested?: CurrencyAmount;

  /**  */
  paid?: CurrencyAmount;
}

export interface AuthenticationProperties {
  /**  */
  items?: object;
}

export interface BeadPaySettingsDto {
  /**  */
  learnMoreUrl?: string;
}

export interface BeadPaySettingsDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: BeadPaySettingsDto;
}

export interface BooleanApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: boolean;
}

export interface ChallengeResult {
  /**  */
  authenticationSchemes?: string[];

  /**  */
  properties?: AuthenticationProperties;
}

export interface ChangeEmailCommand {
  /**  */
  oldEmail?: string;

  /**  */
  newEmail?: string;

  /**  */
  currentPassword?: string;
}

export interface ChangePasswordCommand {
  /**  */
  password?: string;

  /**  */
  newPassword?: string;

  /**  */
  confirmNewPassword?: string;
}

export interface CitationAttachmentEditDto {
  /**  */
  id?: number;

  /**  */
  description?: string;
}

export interface CitationAttachmentListItemDto {
  /**  */
  id?: number;

  /**  */
  fileResourceGuid?: string;

  /**  */
  description?: string;

  /**  */
  fileResourceFileName?: string;

  /**  */
  createdTimestamp?: Date;

  /**  */
  createdByUserName?: string;
}

export interface CitationAttachmentListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: CitationAttachmentListItemDto[];
}

export interface CitationAttachmentListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: CitationAttachmentListItemDtoDataTablePage;
}

export interface CitationDetailsDto {
  /**  */
  id?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  citationNumber?: string;

  /**  */
  citationDate?: Date;

  /**  */
  courtDate?: Date;

  /**  */
  statusName?: string;

  /**  */
  courseViolationLevelName?: string;

  /**  */
  isPaymentEligible?: boolean;

  /**  */
  fineAmount?: number;

  /**  */
  feeAmount?: number;

  /**  */
  courtFee?: number;

  /**  */
  finePaidDate?: Date;

  /**  */
  restitutionNumber?: string;

  /**  */
  courseStatus?: string;

  /**  */
  courseActionStatus?: string;

  /**  */
  courseCompletionDate?: Date;

  /**  */
  violationCitationFeeSum?: number;

  /**  */
  violationCourtFeeSum?: number;

  /**  */
  totalFineAmount?: number;

  /**  */
  lessPaymentsReceived?: number;

  /**  */
  totalDue?: number;

  /**  */
  hasRestitution?: boolean;

  /**  */
  isCourseComplete?: boolean;

  /**  */
  isCourseEligible?: boolean;

  /**  */
  courseFee?: number;

  /**  */
  isFinePaid?: boolean;

  /**  */
  isCitationStatusPending?: boolean;

  /**  */
  isPastDue?: boolean;

  /**  */
  isCitationResolved?: boolean;

  /**  */
  continuanceDate?: Date;

  /**  */
  course?: CourseDto;

  /**  */
  region?: RegionDetailsDto;

  /**  */
  violations?: ViolationListItemDto[];
}

export interface CitationDetailsDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: CitationDetailsDto;
}

export interface CitationListItemDto {
  /**  */
  id?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  citationNumber?: string;

  /**  */
  citationDate?: Date;

  /**  */
  courtDate?: Date;

  /**  */
  statusName?: string;

  /**  */
  courseViolationLevelName?: string;

  /**  */
  isPaymentEligible?: boolean;

  /**  */
  fineAmount?: number;

  /**  */
  feeAmount?: number;

  /**  */
  courtFee?: number;

  /**  */
  finePaidDate?: Date;
}

export interface CitationListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: CitationListItemDto[];
}

export interface CitationListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: CitationListItemDtoDataTablePage;
}

export interface CitationTransactionListItemDto {
  /**  */
  id?: number;

  /**  */
  timestamp?: Date;

  /**  */
  transactionType?: TransactionType;

  /**  */
  paymentMethodPrettyName?: string;

  /**  */
  productPrettyName?: string;

  /**  */
  amount?: number;

  /**  */
  depositId?: number;

  /**  */
  depositNumber?: string;

  /**  */
  canEditDeposit?: boolean;

  /**  */
  canRefund?: boolean;

  /**  */
  canReturn?: boolean;

  /**  */
  canVoid?: boolean;
}

export interface CitationTransactionListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: CitationTransactionListItemDto[];
}

export interface CitationTransactionListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: CitationTransactionListItemDtoDataTablePage;
}

export interface CourseDto {
  /**  */
  name?: string;

  /**  */
  violationLevelName?: string;
}

export interface CourseLoginUrlDto {
  /**  */
  loginUrl?: string;
}

export interface CourseLoginUrlDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: CourseLoginUrlDto;
}

export interface CreateDepositCommand {
  /**  */
  depositNumber?: string;

  /**  */
  depositDate?: Date;

  /**  */
  transactionIds?: number[];
}

export interface CreatePaymentCommandRequest {
  /**  */
  paymentDate?: Date;

  /**  */
  paymentMethodId?: number;

  /**  */
  checkNumber?: string;

  /**  */
  amount?: number;
}

export interface CreateStaffNoteCommand {
  /**  */
  citationId?: number;

  /**  */
  content?: string;
}

export interface CreateUserCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  userRole?: number;

  /**  */
  profilePhoto?: any;
}

export interface CreatedEntity {
  /**  */
  type?: string;

  /**  */
  id?: number;
}

export interface Currency {
  /**  */
  id?: number;

  /**  */
  code?: string;

  /**  */
  name?: string;

  /**  */
  symbol?: string;
}

export interface CurrencyAmount {
  /**  */
  inPaymentCurrency?: PaymentCurrency;

  /**  */
  inRequestedCurrency?: RequestedCurrency;
}

export interface DataTableColumn {
  /**  */
  propertyName?: string;

  /**  */
  sortDirection?: string;

  /**  */
  searchTerm?: string;
}

export interface DataTableRequest {
  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface DeleteCitationAttachmentCommand {
  /**  */
  id?: number;
}

export interface DepositDraftDto {
  /**  */
  previousCount?: number;

  /**  */
  previousAmount?: string;

  /**  */
  newCount?: number;

  /**  */
  newAmount?: string;

  /**  */
  isValid?: boolean;
}

export interface DepositDraftDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: DepositDraftDto;
}

export interface DepositDraftTransactionListItemDto {
  /**  */
  id?: number;

  /**  */
  timestamp?: Date;

  /**  */
  citationId?: number;

  /**  */
  citationNumber?: string;

  /**  */
  transactionType?: TransactionType;

  /**  */
  paymentMethodPrettyName?: string;

  /**  */
  amount?: number;

  /**  */
  hasDeposit?: boolean;
}

export interface DepositDraftTransactionListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: DepositDraftTransactionListItemDto[];
}

export interface DepositDraftTransactionListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: DepositDraftTransactionListItemDtoDataTablePage;
}

export interface DepositDto {
  /**  */
  id?: number;

  /**  */
  depositDate?: Date;

  /**  */
  depositNumber?: string;

  /**  */
  depositStatusId?: number;

  /**  */
  depositStatusName?: string;

  /**  */
  amount?: string;

  /**  */
  transactionCount?: number;
}

export interface DepositDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: DepositDto;
}

export interface DepositListItemDto {
  /**  */
  id?: number;

  /**  */
  depositDate?: Date;

  /**  */
  depositId?: number;

  /**  */
  depositNumber?: string;

  /**  */
  amount?: number;

  /**  */
  depositStatusId?: number;

  /**  */
  depositStatusName?: string;

  /**  */
  closeDate?: Date;
}

export interface DepositListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: DepositListItemDto[];
}

export interface DepositListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: DepositListItemDtoDataTablePage;
}

export interface DepositTransactionListItemDto {
  /**  */
  id?: number;

  /**  */
  timestamp?: Date;

  /**  */
  transactionType?: TransactionType;

  /**  */
  paymentMethodPrettyName?: string;

  /**  */
  citationId?: string;

  /**  */
  citationNumber?: string;

  /**  */
  amount?: number;

  /**  */
  canRemove?: boolean;
}

export interface DepositTransactionListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: DepositTransactionListItemDto[];
}

export interface DepositTransactionListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: DepositTransactionListItemDtoDataTablePage;
}

export interface EditCitationCommand {
  /**  */
  id?: number;

  /**  */
  courtDate?: Date;

  /**  */
  continuanceDate?: Date;

  /**  */
  ldwfNumber?: string;

  /**  */
  emailAddress?: string;

  /**  */
  region?: number;

  /**  */
  district?: string;

  /**  */
  isPaymentEligible?: boolean;

  /**  */
  citationAddress?: Address;

  /**  */
  currentAddress?: Address;

  /**  */
  violations?: object;
}

export interface EditDepositRequest {
  /**  */
  depositNumber?: string;

  /**  */
  depositDate?: Date;
}

export interface EditViolationCommand {
  /**  */
  wildlifeAndFisheriesCode?: string;

  /**  */
  description?: string;

  /**  */
  id?: number;

  /**  */
  dispositionId?: Dispositions;

  /**  */
  dispositionDate?: Date;

  /**  */
  fineAmount?: number;

  /**  */
  courtFee?: number;

  /**  */
  additionalAmount?: number;

  /**  */
  violationLevelId?: number;

  /**  */
  repeatCount?: number;
}

export interface FeatureFlagsDto {
  /**  */
  beadPay?: boolean;

  /**  */
  returns?: boolean;
}

export interface FeatureFlagsDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: FeatureFlagsDto;
}

export interface ForgotPasswordCommand {
  /**  */
  email?: string;
}

export interface GetAmountsPaidReportFileByTransactionDateRangeQuery {
  /**  */
  startTimestamp?: Date;

  /**  */
  endTimestamp?: Date;
}

export interface GetBeadPayReportQuery {
  /**  */
  fromTimestamp?: Date;

  /**  */
  toTimestamp?: Date;
}

export interface GetDailyBalancingReportFileByDepositQuery {
  /**  */
  depositId?: number;
}

export interface GetDepositDraftQuery {
  /**  */
  transactionIds?: number[];

  /**  */
  depositId?: number;
}

export interface GetDepositDraftTransactionsDataTableRequest {
  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;

  /**  */
  transactionIds?: number[];

  /**  */
  depositId?: number;
}

export interface GetDepositsDataTableRequest {
  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;

  /**  */
  depositIds?: number[];

  /**  */
  depositStatusId?: number;

  /**  */
  amount?: number;

  /**  */
  depositStartDate?: Date;

  /**  */
  depositEndDate?: Date;

  /**  */
  closeStartDate?: Date;

  /**  */
  closeEndDate?: Date;
}

export interface GetDocketReportQuery {
  /**  */
  regionFilter?: number;

  /**  */
  hearingRangeStart?: Date;

  /**  */
  hearingRangeEnd?: Date;
}

export interface GetLegacyDocketReportQuery {
  /**  */
  regionFilter?: number;

  /**  */
  hearingRangeStart?: Date;

  /**  */
  hearingRangeEnd?: Date;
}

export interface GetMonthlyBalancingReportFileByMonthQuery {
  /**  */
  year?: number;

  /**  */
  month?: number;
}

export interface GetPaymentMethodOptionsQuery {}

export interface GetReportFileQuery {
  /**  */
  reportName?: string;

  /**  */
  parameters?: object;

  /**  */
  fileType?: string;
}

export interface GetTransactionsDataTableRequest {
  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;

  /**  */
  depositId?: number;

  /**  */
  paymentMethodIds?: number[];

  /**  */
  paymentStartDate?: Date;

  /**  */
  paymentEndDate?: Date;

  /**  */
  depositStartDate?: Date;

  /**  */
  depositEndDate?: Date;
}

export interface LoggedInUserDetailsDto {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberConfirmed?: boolean;

  /**  */
  userRole?: number;

  /**  */
  profilePhotoGuid?: string;

  /**  */
  roleName?: string;

  /**  */
  claimList?: string[];
}

export interface LoggedInUserDetailsDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: LoggedInUserDetailsDto;
}

export interface NoteListItemDto {
  /**  */
  createdDate?: Date;

  /**  */
  typeName?: string;

  /**  */
  userName?: string;

  /**  */
  content?: string;
}

export interface NoteListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: NoteListItemDto[];
}

export interface NoteListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: NoteListItemDtoDataTablePage;
}

export interface OptionItemDto {
  /**  */
  value?: number;

  /**  */
  label?: string;

  /**  */
  metadata?: any | null;

  /**  */
  isDisabled?: boolean;
}

export interface OptionItemDtoListApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: OptionItemDto[];
}

export interface PasswordLoginCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  rememberMe?: boolean;
}

export interface PayCivilFineUsingBeadPayCommand {
  /**  */
  citationId?: number;
}

export interface PayCivilFineUsingBeadPayResponse {
  /**  */
  paymentUrl?: string;
}

export interface PayCivilFineUsingBeadPayResponseApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: PayCivilFineUsingBeadPayResponse;
}

export interface PaymentCurrency {
  /**  */
  amount?: number;

  /**  */
  currency?: Currency;
}

export interface PaymentDto {
  /**  */
  id?: number;

  /**  */
  timestamp?: Date;

  /**  */
  transactionSourceId?: number;

  /**  */
  paymentMethodId?: number;

  /**  */
  amount?: number;
}

export interface PaymentDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: PaymentDto;
}

export interface PaymentStatus {
  /**  */
  value?: string;

  /**  */
  code?: number;
}

export interface PaymentWebhookPayload {
  /**  */
  trackingId?: string;

  /**  */
  paymentCode?: string;

  /**  */
  statusCode?: PaymentStatus;

  /**  */
  amounts?: Amounts;

  /**  */
  reference?: string;

  /**  */
  description?: string;

  /**  */
  receivedTime?: string;

  /**  */
  terminalId?: string;

  /**  */
  merchantId?: string;
}

export interface RefundPaymentCommand {
  /**  */
  timestamp?: Date;

  /**  */
  paymentId?: number;

  /**  */
  transactionSourceId?: number;

  /**  */
  externalId?: string;

  /**  */
  paymentMethodId?: number;

  /**  */
  amount?: number;

  /**  */
  checkNumber?: string;

  /**  */
  wlfInvoiceNumber?: string;

  /**  */
  laGovDocumentNumber?: string;
}

export interface RegionDetailsDto {
  /**  */
  regionNumber?: string;

  /**  */
  district?: string;

  /**  */
  parishName?: string;
}

export interface RegisterUserCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;
}

export interface RequestedCurrency {
  /**  */
  amount?: number;

  /**  */
  currency?: Currency;
}

export interface ResetPasswordCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  code?: string;
}

export interface ReturnPaymentCommand {
  /**  */
  paymentId?: number;

  /**  */
  externalId?: string;

  /**  */
  amount?: number;

  /**  */
  timestamp?: Date;
}

export interface SearchCitationsListItemDto {
  /**  */
  id?: number;

  /**  */
  citationNumber?: string;

  /**  */
  defendantName?: string;

  /**  */
  citationDate?: Date;

  /**  */
  courtDate?: Date;

  /**  */
  statusName?: string;

  /**  */
  courseViolationLevelName?: string;

  /**  */
  fineAmount?: number;

  /**  */
  feeAmount?: number;

  /**  */
  courtFee?: number;

  /**  */
  finePaidDate?: Date;

  /**  */
  isCourseEligible?: boolean;

  /**  */
  courseFee?: number;
}

export interface SearchCitationsListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: SearchCitationsListItemDto[];
}

export interface SearchCitationsListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: SearchCitationsListItemDtoDataTablePage;
}

export interface SendConfirmAccountEmailCommand {
  /**  */
  email?: string;
}

export interface SetTransactionDepositRequest {
  /**  */
  depositId?: number;
}

export interface SettingDto {
  /**  */
  id?: number;

  /**  */
  key?: string;

  /**  */
  value?: string;

  /**  */
  isEnabled?: boolean;
}

export interface SettingDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: SettingDto;
}

export interface SettingDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: SettingDto[];
}

export interface SettingDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: SettingDtoDataTablePage;
}

export interface StaffNotePreviewListItemDto {
  /**  */
  id?: number;

  /**  */
  content?: string;

  /**  */
  userName?: string;

  /**  */
  createdDate?: Date;
}

export interface StatusResult {
  /**  */
  name?: string;

  /**  */
  status?: string;
}

export interface StatusResultListApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: StatusResult[];
}

export interface StringApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: string;
}

export interface TransactionDto {
  /**  */
  id?: number;

  /**  */
  timestamp?: Date;

  /**  */
  transactionType?: string;

  /**  */
  transactionSourceId?: number;

  /**  */
  paymentMethodId?: number;

  /**  */
  amount?: number;
}

export interface TransactionDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: TransactionDto;
}

export interface TransactionListItemDto {
  /**  */
  id?: number;

  /**  */
  timestamp?: Date;

  /**  */
  transactionType?: TransactionType;

  /**  */
  paymentMethodPrettyName?: string;

  /**  */
  citationId?: number;

  /**  */
  citationNumber?: string;

  /**  */
  amount?: number;

  /**  */
  depositId?: number;

  /**  */
  depositNumber?: string;

  /**  */
  hasDeposit?: boolean;

  /**  */
  canRefund?: boolean;

  /**  */
  canReturn?: boolean;

  /**  */
  canVoid?: boolean;
}

export interface TransactionListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: TransactionListItemDto[];
}

export interface TransactionListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: TransactionListItemDtoDataTablePage;
}

export interface UpdateUserCommand {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  username?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  userRole?: number;

  /**  */
  profilePhoto?: any;
}

export interface UserDto {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberConfirmed?: boolean;

  /**  */
  userRole?: number;

  /**  */
  profilePhotoGuid?: string;
}

export interface UserDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: UserDto;
}

export interface UserListItemDto {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberConfirmed?: boolean;
}

export interface UserListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: UserListItemDto[];
}

export interface UserListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: UserListItemDtoDataTablePage;
}

export interface ValidationError {
  /**  */
  propertyName?: string;

  /**  */
  errorMessage?: string;
}

export interface ViolationListItemDto {
  /**  */
  id?: number;

  /**  */
  wildlifeAndFisheriesCode?: string;

  /**  */
  description?: string;

  /**  */
  repeatCount?: number;

  /**  */
  violationLevelName?: string;

  /**  */
  violationAmount?: number;

  /**  */
  dispositionName?: string;

  /**  */
  fineAmount?: number;

  /**  */
  courtFee?: number;

  /**  */
  additionalAmount?: number;
}

export interface ViolatorTransactionListItemDto {
  /**  */
  timestamp?: Date;

  /**  */
  transactionType?: TransactionType;

  /**  */
  paymentMethodPrettyName?: string;

  /**  */
  productPrettyName?: string;

  /**  */
  amount?: number;
}

export interface ViolatorTransactionListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ViolatorTransactionListItemDto[];
}

export interface ViolatorTransactionListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: ViolatorTransactionListItemDtoDataTablePage;
}

export interface VoidTransactionCommand {
  /**  */
  transactionId?: number;

  /**  */
  timestamp?: Date;
}

export type Dispositions = 0 | 1 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type TransactionType = 0 | 1 | 2 | 3;
