import { createAsyncThunk } from '@reduxjs/toolkit';
import { FeatureService } from 'api';

export const fetchFeatureFlags = createAsyncThunk(
  'features/fetch-feature-flags',
  async () => {
    const { result } = await FeatureService.getFeatureFlags();
    return result;
  }
);
