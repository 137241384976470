import classnames from 'classnames';
import { Collapse } from 'reactstrap';
import { useSubBanner } from 'shared/contexts';

export default function SubBanner() {
  const { className, children } = useSubBanner();
  return (
    <Collapse isOpen={!!children} className="c-header-sub-banner">
      <header className={classnames('c-header-nav c-header-fixed', className)}>
        {children}
      </header>
    </Collapse>
  );
}
