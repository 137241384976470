// this is auto generated, don't try and change it
const routes = [
  {importPath: './src/routes/403.tsx', path: '403', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/403')},
  {importPath: './src/routes/404.tsx', path: '404', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/404')},
  {importPath: './src/routes/Admin/_Layout.tsx', path: 'Admin/_Layout', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/_Layout')},
  {importPath: './src/routes/Admin/404.tsx', path: 'Admin/404', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/404')},
  {importPath: './src/routes/Admin/Accounting/Deposits/Details/$id.tsx', path: 'Admin/Accounting/Deposits/Details/:id', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Accounting/Deposits/Details/$id')},
  {importPath: './src/routes/Admin/Accounting/Deposits/Draft.tsx', path: 'Admin/Accounting/Deposits/Draft', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Accounting/Deposits/Draft')},
  {importPath: './src/routes/Admin/Accounting/Deposits/index.tsx', path: 'Admin/Accounting/Deposits/', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Accounting/Deposits/index')},
  {importPath: './src/routes/Admin/Accounting/index.tsx', path: 'Admin/Accounting/', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Accounting/index')},
  {importPath: './src/routes/Admin/Accounting/Transactions.tsx', path: 'Admin/Accounting/Transactions', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Accounting/Transactions')},
  {importPath: './src/routes/Admin/Citations/_Layout.tsx', path: 'Admin/Citations/_Layout', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Citations/_Layout')},
  {importPath: './src/routes/Admin/Citations/$id/_Layout.tsx', path: 'Admin/Citations/:id/_Layout', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Citations/$id/_Layout')},
  {importPath: './src/routes/Admin/Citations/$id/Attachments.tsx', path: 'Admin/Citations/:id/Attachments', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Citations/$id/Attachments')},
  {importPath: './src/routes/Admin/Citations/$id/Course.tsx', path: 'Admin/Citations/:id/Course', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Citations/$id/Course')},
  {importPath: './src/routes/Admin/Citations/$id/Details.tsx', path: 'Admin/Citations/:id/Details', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Citations/$id/Details')},
  {importPath: './src/routes/Admin/Citations/$id/History.tsx', path: 'Admin/Citations/:id/History', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Citations/$id/History')},
  {importPath: './src/routes/Admin/Citations/$id/Summary.tsx', path: 'Admin/Citations/:id/Summary', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Citations/$id/Summary')},
  {importPath: './src/routes/Admin/Citations/$id/Transactions/Create.tsx', path: 'Admin/Citations/:id/Transactions/Create', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Citations/$id/Transactions/Create')},
  {importPath: './src/routes/Admin/Citations/$id/Transactions/index.tsx', path: 'Admin/Citations/:id/Transactions/', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Citations/$id/Transactions/index')},
  {importPath: './src/routes/Admin/Citations/index.tsx', path: 'Admin/Citations/', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Citations/index')},
  {importPath: './src/routes/Admin/Citations/Search/_Layout.tsx', path: 'Admin/Citations/Search/_Layout', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Citations/Search/_Layout')},
  {importPath: './src/routes/Admin/Citations/Search/Advanced.tsx', path: 'Admin/Citations/Search/Advanced', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Citations/Search/Advanced')},
  {importPath: './src/routes/Admin/Citations/Search/index.tsx', path: 'Admin/Citations/Search/', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Citations/Search/index')},
  {importPath: './src/routes/Admin/Citations/Search/Results.tsx', path: 'Admin/Citations/Search/Results', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Citations/Search/Results')},
  {importPath: './src/routes/Admin/index.tsx', path: 'Admin/', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/index')},
  {importPath: './src/routes/Admin/Reports/AmountsPaidReport.tsx', path: 'Admin/Reports/AmountsPaidReport', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Reports/AmountsPaidReport')},
  {importPath: './src/routes/Admin/Reports/BeadPayReport.tsx', path: 'Admin/Reports/BeadPayReport', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Reports/BeadPayReport')},
  {importPath: './src/routes/Admin/Reports/CitationDocketReport.tsx', path: 'Admin/Reports/CitationDocketReport', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Reports/CitationDocketReport')},
  {importPath: './src/routes/Admin/Reports/DailyBalancingReport.tsx', path: 'Admin/Reports/DailyBalancingReport', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Reports/DailyBalancingReport')},
  {importPath: './src/routes/Admin/Reports/index.tsx', path: 'Admin/Reports/', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Reports/index')},
  {importPath: './src/routes/Admin/Reports/LegacyDocketReport.tsx', path: 'Admin/Reports/LegacyDocketReport', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Reports/LegacyDocketReport')},
  {importPath: './src/routes/Admin/Reports/MonthlyBalancingReport.tsx', path: 'Admin/Reports/MonthlyBalancingReport', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Reports/MonthlyBalancingReport')},
  {importPath: './src/routes/Admin/Users.tsx', path: 'Admin/Users', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Users')},
  {importPath: './src/routes/ChangePassword.tsx', path: 'ChangePassword', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/ChangePassword')},
  {importPath: './src/routes/ConfirmAccount.tsx', path: 'ConfirmAccount', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/ConfirmAccount')},
  {importPath: './src/routes/Defendant/_Layout.tsx', path: 'Defendant/_Layout', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Defendant/_Layout')},
  {importPath: './src/routes/Defendant/Citations/_Layout.tsx', path: 'Defendant/Citations/_Layout', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Defendant/Citations/_Layout')},
  {importPath: './src/routes/Defendant/Citations/$id/_Layout.tsx', path: 'Defendant/Citations/:id/_Layout', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Defendant/Citations/$id/_Layout')},
  {importPath: './src/routes/Defendant/Citations/$id/Details.tsx', path: 'Defendant/Citations/:id/Details', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Defendant/Citations/$id/Details')},
  {importPath: './src/routes/Defendant/Citations/$id/Transactions.tsx', path: 'Defendant/Citations/:id/Transactions', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Defendant/Citations/$id/Transactions')},
  {importPath: './src/routes/Defendant/Citations/Search/index.tsx', path: 'Defendant/Citations/Search/', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Defendant/Citations/Search/index')},
  {importPath: './src/routes/Defendant/Citations/Search/Results.tsx', path: 'Defendant/Citations/Search/Results', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Defendant/Citations/Search/Results')},
  {importPath: './src/routes/Defendant/index.tsx', path: 'Defendant/', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Defendant/index')},
  {importPath: './src/routes/ForgotPassword.tsx', path: 'ForgotPassword', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/ForgotPassword')},
  {importPath: './src/routes/ForgotPasswordSuccess.tsx', path: 'ForgotPasswordSuccess', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/ForgotPasswordSuccess')},
  {importPath: './src/routes/index.tsx', path: '', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/index')},
  {importPath: './src/routes/Login.tsx', path: 'Login', importFunc: () => import(/* webpackMode: "eager" *//* webpackChunkName: "login" */'./routes/Login')},
  {importPath: './src/routes/Register.tsx', path: 'Register', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Register')},
  {importPath: './src/routes/ResetPassword.tsx', path: 'ResetPassword', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/ResetPassword')},
  {importPath: './src/routes/ResetPasswordConfirmation.tsx', path: 'ResetPasswordConfirmation', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/ResetPasswordConfirmation')},
];

export default routes;