// https://stackoverflow.com/a/14428340
export function formatMoney(s: any) {
  if (s === null || s === undefined) {
    return null;
  }

  const numeric = parseFloat(s);
  if (isNaN(numeric) || !isFinite(numeric)) {
    return s;
  }

  if (numeric >= 0) {
    return '$' + numeric.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  } else {
    return `($${(-numeric).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')})`;
  }
}
