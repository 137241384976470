import './Body.scss';
import classnames from 'classnames';
import { Container } from 'reactstrap';
import { ClassNameProps, ChildrenProps } from 'typings';
import { useBanner, useSubBanner } from 'shared/contexts';

type BodyProps = ClassNameProps & ChildrenProps;

export default function Body({ className, children }: BodyProps) {
  const banner = useBanner();
  const subBanner = useSubBanner();

  const withBanner = !!banner.children;
  const withSubBanner = !!subBanner.children;

  return (
    <div
      className={classnames('c-body', {
        'with-banner': withBanner,
        'with-sub-banner': withSubBanner,
      })}>
      <Container>
        <div className={classnames('c-main', className)}>{children}</div>
      </Container>
    </div>
  );
}
