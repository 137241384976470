import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'ui',
  initialState: {
    sidebar: {
      isOpen: true,
    },
  },
  reducers: {
    showSidebar: state => {
      state.sidebar.isOpen = true;
    },
    hideSidebar: state => {
      state.sidebar.isOpen = false;
    },
    toggleSidebarState: state => {
      state.sidebar.isOpen = !state.sidebar.isOpen;
    },
  },
});

export const { actions, reducer } = slice;
export type UiSlice = ReturnType<typeof reducer>;
