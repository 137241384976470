import React from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from 'shared/state';

export default function Logout() {
  const dispatch = useDispatch();

  return (
    <div onClick={logout}>
      <i className="nav-icon mfe-2 icon-logout" /> Logout
    </div>
  );

  function logout() {
    dispatch(authActions.logout());
  }
}
