export default function FileResourceCell({
  value,
  className,
  style,
  row,
}: any) {
  return (
    <a
      href={`/api/admin/file-resources/${row.original.fileResourceGuid}`}
      title={`Download ${value}`}
      style={style}
      className={className}>
      {value}
    </a>
  );
}
