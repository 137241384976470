import './Brand.scss';

export default function Brand() {
  return (
    <header className="c-header-nav c-header-fixed c-header-top">
      <img
        src={`${process.env.PUBLIC_URL}/img/logo.svg`}
        alt="Navbar Logo"
        height="136"
        width="136"
      />
      <div>
        <div className="title">
          Louisiana Department of Wildlife and Fisheries
        </div>
        <div className="subtitle">Civil Fines Payment and Course System</div>
      </div>
    </header>
  );
}
