import { createClaimHook } from './createClaimHook';

export interface UseRolesHookResult<TRole extends string> {
  roles: TRole[];
  hasRole: (role: TRole) => boolean;
  hasAnyRole: (roles: TRole[]) => boolean;
}

export interface UseRolesHook<TRole extends string> {
  (): UseRolesHookResult<TRole>;
}

export function createRoleHook<TRole extends string>(
  useSelectRoles: () => TRole[]
): UseRolesHook<TRole> {
  const useClaims = createClaimHook(useSelectRoles);

  return function useRoles() {
    const { claims, hasClaim, hasAnyClaim } = useClaims();
    return {
      roles: claims,
      hasRole: hasClaim,
      hasAnyRole: hasAnyClaim,
    };
  };
}
