import classnames from 'classnames';
import { FormattedDate, useIntl } from 'react-intl';
import Cell from './Cell';

export default function CustomDateCell({
  value,
  className,
  title,
  ...props
}: any) {
  const intl = useIntl();
  const localDate = /\d{4}-\d{2}-\d{2}$/.test(value)
    ? value + 'T00:00:00.000'
    : value;
  const dateNumber = !value ? NaN : Date.parse(localDate);
  const isValid = !isNaN(dateNumber);
  return (
    <Cell
      value={isValid ? <FormattedDate value={dateNumber} /> : value}
      className={classnames(className, 'date-cell')}
      title={isValid ? intl.formatDate(dateNumber) : title}
      {...props}
    />
  );
}
