import { createClaimHook } from './createClaimHook';

export interface UsePermissionsHookResult<TPermission extends string> {
  permissions: TPermission[];
  hasPermission: (permission: TPermission) => boolean;
  hasAnyPermission: (permissions: TPermission[]) => boolean;
}

export interface UsePermissionsHook<TPermission extends string> {
  (): UsePermissionsHookResult<TPermission>;
}

export function createPermissionHook<TPermission extends string>(
  useSelectPermissions: () => TPermission[]
): UsePermissionsHook<TPermission> {
  const useClaims = createClaimHook(useSelectPermissions);

  return function usePermissions() {
    const { claims, hasClaim, hasAnyClaim } = useClaims();
    return {
      permissions: claims,
      hasPermission: hasClaim,
      hasAnyPermission: hasAnyClaim,
    };
  };
}
