import { NavigationContextValue } from 'typings';
import { createStrictContext } from '../utils';

const [NavigationProvider, useNavigation] = createStrictContext<
  NavigationContextValue<any>
>({
  name: 'Navigation',
});

export { NavigationProvider, useNavigation };
