import { BannerContextValue } from 'typings';
import { createStrictContext } from '../utils';

const [SubBannerProvider, useSubBanner] = createStrictContext<
  BannerContextValue
>({
  name: 'SubBanner',
});

export { SubBannerProvider, useSubBanner };
