import classnames from 'classnames';
import { Collapse } from 'reactstrap';
import { useBanner } from 'shared/contexts';

export default function Banner() {
  const { className, children } = useBanner();
  return (
    <Collapse isOpen={!!children} className="c-header-banner">
      <header className={classnames('c-header-nav c-header-fixed', className)}>
        {children}
      </header>
    </Collapse>
  );
}
