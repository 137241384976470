import { Authorized } from 'features/Auth';
import { Link } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Button } from 'reactstrap';

interface DepositLinkable {
  depositId: number;
  depositNumber: string;
}

export default function DepositLinkCell<
  TDepositLinkable extends DepositLinkable
>({ row: { original } }: CellProps<TDepositLinkable>) {
  return (
    <Authorized
      requires="Deposits - View Details"
      fallback={original.depositNumber}>
      <Button
        tag={Link}
        to={`/admin/accounting/deposits/details/${original.depositId}`}
        title="Navigate to Deposit"
        color="link"
        target="_blank"
        rel="noopener noreferrer">
        {original.depositNumber}
      </Button>
    </Authorized>
  );
}
