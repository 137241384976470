import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'envoc-form/dist/css/envoc-form-styles.css';
import 'envoc-table/dist/css/envoc-table-styles.css';
import { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { configureAxios, configureServices, configureTableCells } from 'config';
import {
  AxiosInterceptor,
  DelayedLoadingToast,
  FeatureManager,
  FileSystemRoutes,
  XsrfToken,
} from 'shared/components';
import {
  BannerProvider,
  NavigationProvider,
  SetBannerProvider,
  SetNavigationProvider,
  SetSubBannerProvider,
  SubBannerProvider,
} from 'shared/contexts';
import { BaseUI } from 'features/BaseUI';

configureAxios();
configureServices();
configureTableCells();

function App() {
  const [navigation, setNavigation] = useState({});
  const [banner, setBanner] = useState({});
  const [subBanner, setSubBanner] = useState({});
  return (
    <IntlProvider locale="en">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <NavigationProvider value={navigation}>
          <SetNavigationProvider value={setNavigation}>
            <BannerProvider value={banner}>
              <SetBannerProvider value={setBanner}>
                <SubBannerProvider value={subBanner}>
                  <SetSubBannerProvider value={setSubBanner}>
                    <BaseUI>
                      <FileSystemRoutes />
                      <XsrfToken />
                      <FeatureManager />
                      <AxiosInterceptor component={DelayedLoadingToast} />
                      <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
                    </BaseUI>
                  </SetSubBannerProvider>
                </SubBannerProvider>
              </SetBannerProvider>
            </BannerProvider>
          </SetNavigationProvider>
        </NavigationProvider>
      </BrowserRouter>
    </IntlProvider>
  );
}

export default App;
