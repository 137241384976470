import { createSlice } from '@reduxjs/toolkit';
import { allowRedirects, loadUser, logout, setNoAuth } from './actions';
import * as authTicketStates from './authTicketStates';
import AuthStateSlice from './types';

const slice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    authError: null,
    noRedirect: false,
    authTicketState: authTicketStates.userAuthTicketNotChecked,
    cookieExpirationMinutes: 15,
  } as AuthStateSlice,
  reducers: {}, //This needs to be here so typescript doesnt complain
  extraReducers: {
    [allowRedirects.type]: state => {
      state.noRedirect = false;
    },
    [setNoAuth.type]: state => {
      state.user = null;
      state.authError = null;
      state.authTicketState = authTicketStates.userAuthTicketNotChecked;
    },
    [loadUser.pending.type]: state => {
      state.user = null;
      state.noRedirect = false;
      state.authError = null;
      state.authTicketState = authTicketStates.userAuthTicketChecking;
    },
    [loadUser.fulfilled.type]: (state, { payload }) => {
      state.user = payload;
      state.authTicketState = authTicketStates.userAuthTicketChecked;
    },
    [loadUser.rejected.type]: (state, { payload }) => {
      state.authError = payload;
      state.authTicketState = authTicketStates.userAuthTicketError;
    },
    [logout.pending.type]: state => {
      state.user = null;
      state.noRedirect = true;
      state.authError = null;
      state.authTicketState = authTicketStates.userAuthTicketChecking;
    },
    [logout.fulfilled.type]: state => {
      state.authTicketState = authTicketStates.userAuthTicketChecked;
    },
    [logout.rejected.type]: (state, { payload }) => {
      state.authError = payload;
      state.authTicketState = authTicketStates.userAuthTicketError;
    },
  },
});

const { reducer } = slice;

export { reducer as default };
