import type { RequireAtLeastOne } from '../../types';
import { createAuthorizedComponent } from '../createAuthorizedComponent';
import { createAuthorizeHook } from '../createAuthorizeHook';
import { createPermissionHook } from '../createPermissionHook';
import { createRoleHook } from '../createRoleHook';
import type {
  Auth,
  CreateAuthOptions,
  CreateRoleAuthOptions,
  CreatePermissionAuthOptions,
  PermissionAuth,
  RoleAuth,
  RoleAndPermissionAuth,
} from './types';

export function createAuth<TRole extends string>(
  options: CreateRoleAuthOptions<TRole>
): RoleAuth<TRole>;
export function createAuth<TPermission extends string>(
  options: CreatePermissionAuthOptions<TPermission>
): PermissionAuth<TPermission>;
export function createAuth<TRole extends string, TPermission extends string>(
  options: CreateAuthOptions<TRole, TPermission>
): RoleAndPermissionAuth<TRole, TPermission>;
export function createAuth<TRole extends string, TPermission extends string>(
  options: RequireAtLeastOne<
    CreateAuthOptions<TRole, TPermission>,
    'useRoleSelector' | 'usePermissionSelector'
  >
): Auth<TRole, TPermission> {
  if (options.useRoleSelector && options.usePermissionSelector) {
    return createRoleAndPermissionAuth(
      options as CreateAuthOptions<TRole, TPermission>
    );
  }
  if (options.useRoleSelector) {
    return createRoleAuth(options as CreateRoleAuthOptions<TRole>);
  }
  if (options.usePermissionSelector) {
    return createPermissionAuth(
      options as CreatePermissionAuthOptions<TPermission>
    );
  }
  throw Error();
}

function createRoleAndPermissionAuth<
  TRole extends string,
  TPermission extends string
>(options: CreateAuthOptions<TRole, TPermission>) {
  const useRoles = createRoleHook(options.useRoleSelector);
  const usePermissions = createPermissionHook(options.usePermissionSelector);
  const useAuthorize = createAuthorizeHook({ useRoles, usePermissions });
  const Authorized = createAuthorizedComponent(useAuthorize);
  return {
    Authorized,
    useAuthorize,
    usePermissions,
    useRoles,
  } as const;
}

function createRoleAuth<TRole extends string>(
  options: CreateRoleAuthOptions<TRole>
) {
  const useRoles = createRoleHook(options.useRoleSelector);
  const useAuthorize = createAuthorizeHook({ useRoles });
  const Authorized = createAuthorizedComponent(useAuthorize);
  return {
    Authorized,
    useAuthorize,
    useRoles,
  } as const;
}

function createPermissionAuth<TPermission extends string>(
  options: CreatePermissionAuthOptions<TPermission>
) {
  const usePermissions = createPermissionHook(options.usePermissionSelector);
  const useAuthorize = createAuthorizeHook({ usePermissions });
  const Authorized = createAuthorizedComponent(useAuthorize);
  return {
    Authorized,
    useAuthorize,
    usePermissions,
  } as const;
}
