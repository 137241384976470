import { ReactNode } from 'react';
import type { UseAuthorizeHook } from '../createAuthorizeHook';
import type {
  UseAuthorizePermissionHook,
  UseAuthorizeRoleHook,
} from '../createAuthorizeHook';
import type {
  AuthorizedChildrenFn,
  AuthorizedComponent,
  AuthorizedPermissionComponent,
  AuthorizedRoleComponent,
  AuthorizedRoleAndPermissionComponent,
  AuthorizedRoleAndPermissionComponentProps,
} from './types';

export function createAuthorizedComponent<TRole extends string>(
  useAuthorize: UseAuthorizeRoleHook<TRole>
): AuthorizedRoleComponent<TRole>;
export function createAuthorizedComponent<TPermission extends string>(
  useAuthorize: UseAuthorizePermissionHook<TPermission>
): AuthorizedPermissionComponent<TPermission>;
export function createAuthorizedComponent<
  TRole extends string,
  TPermission extends string
>(
  useAuthorize: UseAuthorizeHook<TRole, TPermission>
): AuthorizedRoleAndPermissionComponent<TRole, TPermission>;
export function createAuthorizedComponent<
  TRole extends string,
  TPermission extends string
>(
  useAuthorize: UseAuthorizeHook<TRole, TPermission>
): AuthorizedComponent<TRole, TPermission> {
  return function Authorized<
    TChildren extends ReactNode | AuthorizedChildrenFn
  >({
    children,
    fallback,
    requires,
  }: AuthorizedRoleAndPermissionComponentProps<TRole, TPermission, TChildren>) {
    const authorize = useAuthorize();
    const isAuthorized = authorize(requires);
    if (typeof children === 'function') {
      return children({ isAuthorized });
    }
    if (isAuthorized) {
      return <>{children}</>;
    }
    return <>{fallback || null}</>;
  };
}
