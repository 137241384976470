import { sortBy } from 'lodash';
import React, { PropsWithChildren, Suspense, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import routeFiles from 'route-files';

type RouteElement = (props: any) => JSX.Element;

// stolen from react router 6 (beta) so we can jump to Outlet based quickly
export interface RouteConfig {
  children: RouteConfig[];
  element: RouteElement | null; // note: this is normally a 'react node' react router 6 (beta) - but we want to inject children into it here as a workaround
  path: string;
}

export default function FileSystemRoutes() {
  const result = useMemo(() => {
    let root = {
      children: [],
      path: '',
      element: null,
    } as RouteConfig;

    for (let index = 0; index < routeFiles.length; index++) {
      const element = routeFiles[index];
      const Component = React.lazy(element.importFunc as any);

      // see: 2e65dc0521f571936f050b57d888fcdbede2c239 - this will be an awaited element using outlet instead of directly using children
      let parent = root;

      // take each part in a path and ensure we have a route for each name
      // this way we can handle folder routes that have no layout
      const parts = element.path.split('/');
      for (let index = 0; index < parts.length; index++) {
        const part = parts[index];

        const effectivePath =
          part === ''
            ? '/'
            : part === '404'
            ? '*' // note: '*' here eventually means the right thing for react router 6 for 'useRoutes' that takes a configuration object
            : part.startsWith('$')
            ? part.replace('$', ':')
            : part // convert to kebab case
                .replace(/([a-z])([A-Z])/g, '$1-$2')
                .replace(/\s+/g, '-')
                .toLowerCase();

        if (part === '_Layout') {
          // this should wrap all the children, including index routes - there should exist only a single _Layout instance per path part
          const LayoutElement = ({
            children,
            ...rest
          }: PropsWithChildren<any>) => {
            return (
              <Suspense fallback={children}>
                <Component {...rest}>{children}</Component>
              </Suspense>
            );
          };
          parent.element = LayoutElement;
        } else if (index === parts.length - 1) {
          // we are a leaf, go ahead and add
          let item = parent.children.find(x => x.path === effectivePath);
          if (!item) {
            item = {
              path: effectivePath,
              children: [],
              element: null,
            };
            parent.children.push(item);
          }
          // TODO: real loading indicator?
          const Leaf = ({ children, ...rest }: PropsWithChildren<any>) => {
            return (
              <Suspense fallback={children}>
                <Component {...rest}>{children}</Component>
              </Suspense>
            );
          };
          item.element = Leaf;
        } else {
          // we still have folders to parse, keep going deeper
          let newParent = parent.children.find(x => x.path === effectivePath);
          if (!newParent) {
            newParent = {
              path: effectivePath,
              element: null, // TODO we should render Outlet here eventually, our render will handle the null case correctly
              children: [],
            };
            parent.children.push(newParent);
          }

          parent = newParent;
        }
      }
    }
    const children = renderFlatRoutes(
      root,
      root.path === '' ? [] : [root.path],
      root.element === null ? [] : [root.element]
    );

    // so, we don't want to use the 'key' prop because it causes everything to mount and unmount
    // This is like writing out a switch with several route elements manually out - instead of using map(...) with a key
    // e.g. <Switch><Route /><Route /><Route /></Switch> does not need 'key' props on each route
    // this allows React to avoid unmounting a component when the path to that component remains unchanged

    // Assume: Two routes with root -> AdminLayout -> {path}
    // so:
    // 1) <Route path="foo" render={root -> AdminLayout -> Foo} />
    // 2) <Route path="bar" render={root -> AdminLayout -> Bar} />
    // When switching between foo and bar, React renders the following states
    // on '/foo': Route -> root -> AdminLayout -> Foo
    // on '/bar': Route -> root -> AdminLayout -> Bar
    // Since we didn't set the 'key' on the route - React simply sees the same component type at the same location and doesn't need to unmount
    // the only unmounted component would be Foo, replaced by Bar
    // compare that with if each Route above had a key - everything would get unmounted on each route change
    return React.createElement.apply(null, [Switch, {}, ...children]);
  }, []);

  return result;
}

// TODO: better comments
function renderFlatRoutes(
  config: RouteConfig,
  pathsVisited: string[],
  elementsVisited: RouteElement[]
): JSX.Element[] {
  const Element = config.element;
  if (config.children.length === 0 && Element !== null) {
    // we are at the furthest part of the tree, render the route
    const isIndexPage = config.path === '/';
    const isNotFoundPage = config.path === '*';

    const combinedVisits = pathsVisited.join('/');
    const path =
      isNotFoundPage || isIndexPage
        ? `/${combinedVisits.substring(0, combinedVisits.length - 1)}`
        : `/${combinedVisits}`;

    const Item = (
      <Route
        path={path}
        exact={isIndexPage}
        render={routeProps => {
          return elementsVisited.reduceRight(
            (children, Parent) => {
              return <Parent {...routeProps}>{children}</Parent>;
            },
            <Suspense fallback={null}>
              <Element {...routeProps} />
            </Suspense>
          );
        }}
      />
    );
    return [Item];
  }

  const sorted = sortBy(config.children, x =>
    x.path === '/' ? -2 : x.path === '*' ? -1 : -x.children.length - 3
  );

  return sorted
    .map(x =>
      renderFlatRoutes(
        x,
        [...pathsVisited, x.path],
        Element === null ? elementsVisited : [...elementsVisited, Element]
      )
    )
    .flat();
}
