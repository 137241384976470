import { isArray, isBoolean } from 'lodash';
import type {
  RequireAtLeastOne,
  InternalRequirement,
  InternalRoleRequirement,
  InternalPermissionRequirement,
} from '../../types';
import type {
  CreateAuthorizeHookOptions,
  CreateAuthorizePermissionHookOptions,
  CreateAuthorizeRoleHookOptions,
  UseAuthorizeHook,
  UseAuthorizePermissionHook,
  UseAuthorizeRoleHook,
} from './types';

export function createAuthorizeHook<TRole extends string>(
  options: CreateAuthorizeRoleHookOptions<TRole>
): UseAuthorizeRoleHook<TRole>;
export function createAuthorizeHook<TPermission extends string>(
  options: CreateAuthorizePermissionHookOptions<TPermission>
): UseAuthorizePermissionHook<TPermission>;
export function createAuthorizeHook<
  TRole extends string,
  TPermission extends string
>(
  options: CreateAuthorizeHookOptions<TRole, TPermission>
): UseAuthorizeHook<TRole, TPermission>;
export function createAuthorizeHook<
  TRole extends string,
  TPermission extends string
>(
  options: RequireAtLeastOne<CreateAuthorizeHookOptions<TRole, TPermission>>
):
  | UseAuthorizeHook<TRole, TPermission>
  | UseAuthorizeRoleHook<TRole>
  | UseAuthorizePermissionHook<TPermission> {
  if (options.useRoles && options.usePermissions) {
    return createAuthorizeRoleAndPermissionHook(
      options as CreateAuthorizeHookOptions<TRole, TPermission>
    );
  }
  if (options.useRoles) {
    return createAuthorizeRoleHook(
      options as CreateAuthorizeRoleHookOptions<TRole>
    );
  }
  if (options.usePermissions) {
    return createAuthorizePermissionHook(
      options as CreateAuthorizePermissionHookOptions<TPermission>
    );
  }
  throw Error();
}

function createAuthorizeRoleAndPermissionHook<
  TRole extends string,
  TPermission extends string
>(
  options: CreateAuthorizeHookOptions<TRole, TPermission>
): UseAuthorizeHook<TRole, TPermission> {
  return function useAuthorize() {
    const { hasRole } = options.useRoles();
    const { hasPermission } = options.usePermissions();

    return function authorize(
      requirement: InternalRequirement<TRole, TPermission>
    ): boolean {
      if (isArray(requirement)) {
        return requirement.some((singleRequirement) =>
          authorize(singleRequirement)
        );
      }
      if (isBoolean(requirement)) {
        return requirement as boolean;
      }
      return (
        hasRole(requirement as TRole) ||
        hasPermission(requirement as TPermission)
      );
    };
  };
}

function createAuthorizeRoleHook<TRole extends string>(
  options: CreateAuthorizeRoleHookOptions<TRole>
): UseAuthorizeRoleHook<TRole> {
  return function useAuthorize() {
    const { hasRole } = options.useRoles();

    return function authorize(
      requirement: InternalRoleRequirement<TRole>
    ): boolean {
      if (isArray(requirement)) {
        return requirement.some((singleRequirement) =>
          authorize(singleRequirement)
        );
      }
      if (isBoolean(requirement)) {
        return requirement as boolean;
      }
      return hasRole(requirement as TRole);
    };
  };
}

function createAuthorizePermissionHook<TPermission extends string>(
  options: CreateAuthorizePermissionHookOptions<TPermission>
): UseAuthorizeRoleHook<TPermission> {
  return function useAuthorize() {
    const { hasPermission } = options.usePermissions();

    return function authorize(
      requirement: InternalPermissionRequirement<TPermission>
    ): boolean {
      if (isArray(requirement)) {
        return requirement.some((singleRequirement) =>
          authorize(singleRequirement)
        );
      }
      if (isBoolean(requirement)) {
        return requirement as boolean;
      }
      return hasPermission(requirement as TPermission);
    };
  };
}
