import { useCallback } from 'react';

export interface UseClaimsHookResult<TClaim extends string> {
  claims: TClaim[];
  hasClaim: (claim: TClaim) => boolean;
  hasAnyClaim: (claims: TClaim[]) => boolean;
}

export interface UseClaimsHook<TClaim extends string> {
  (): UseClaimsHookResult<TClaim>;
}

export function createClaimHook<TClaim extends string>(
  useSelectClaims: () => TClaim[]
): UseClaimsHook<TClaim> {
  return function useClaims() {
    const claims = useSelectClaims();
    const hasClaim = useCallback(hasClaimFn, [claims]);
    const hasAnyClaim = useCallback(hasAnyClaimFn, [claims]);

    return {
      claims,
      hasClaim,
      hasAnyClaim,
    };

    function hasClaimFn(claim: TClaim) {
      return claims.includes(claim);
    }

    function hasAnyClaimFn(requiredClaims: TClaim[]) {
      return claims.some(claims => requiredClaims.includes(claims));
    }
  };
}
