import classnames from 'classnames';

export default function HtmlCell({ value, className, style, title }: any) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: value }}
      title={title}
      style={style}
      className={classnames(className, 'cell')}
    />
  );
}
