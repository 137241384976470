import { Dispatch, SetStateAction } from 'react';
import { BannerContextValue } from 'typings';
import { createStrictContext } from '../utils';

const [SetBannerProvider, useSetBanner] = createStrictContext<
  Dispatch<SetStateAction<BannerContextValue>>
>({
  name: 'SetBanner',
});

export { SetBannerProvider, useSetBanner };
