import { Dispatch, SetStateAction } from 'react';
import { NavigationContextValue } from 'typings';
import { createStrictContext } from '../utils';

const [SetNavigationProvider, useSetNavigation] = createStrictContext<
  Dispatch<SetStateAction<NavigationContextValue<any>>>
>({
  name: 'SetNavigation',
});

export { SetNavigationProvider, useSetNavigation };
